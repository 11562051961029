/**
 * HighCharts utilities that are used to build the demand HighChart.
 * These functions set up the default parameters along with some
 * helper functions for formatting the visual elements of the chart.
 */

import { commodityTypes } from "../../../common/config";
import { getDailyLabels, getWeeklyLabels } from "../../../common/axisLabels";

import Highcharts from "highcharts";

// Established the default graph parameters.
export const initializeGraph = async (data, selection) => {
  return {
    chart: {
      type: "areaspline",
      plotBorderColor: "#F1F1F2",
      plotBorderWidth: 2,
      height: 310
    },
    title: {
      text: null
    },
    xAxis: await setXAxis(selection),
    yAxis: await setYAxis(),
    legend: {
      enabled: false
    },
    plotOptions: {
      areaspline: {
        marker: {
          enabled: false
        }
      },
      series: {
        stacking: "normal",
        states: {
          hover: {
            enabled: false
          },
          fillOpacity: 0.1
        },
        animation: {
          duration: 2000,
          easing: "easeOutBounce"
        }
      }
    },
    tooltip: {
      backgroundColor: "#f1f1f2",
      borderColor: "#92949a",
      borderRadius: 1,
      borderWidth: 1.5,
      shadow: false,
      crosshairs: [
        {
          width: 1,
          color: "#222831",
          zIndex: 5
        }
      ],
      pointFormat:
        '<span style="color:{series.color};font-weight:700;">\u25CF {series.name}:</span> {point.y}<br/>',
      shared: true,
      xDateFormat: "%A, %b %e, %l:%M%P"
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    series: await buildSeries(data),
    visible: true
  };
};

// Sets the parameters for each of the commodities. This includes
// color, tooltip information, and visibility status.
export const buildSeries = async commodityData => {
  let seriesData = await buildBaseCommodities(commodityData);
  return seriesData;
};

const buildBaseCommodities = data => {
  let tempSeries = [];
  tempSeries.push({
    name: "Electricity",
    id: "electricity",
    data: formatData(data),
    tooltip: {
      valueDecimals: 0,
      valueSuffix: " kBtu/hr"
    },
    yAxis: 0,
    color: commodityTypes["electricity"].color,
    fillColor: {
      linearGradient: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1
      },
      stops: [
        [
          0,
          "#98BF47"
        ],
        [
          1,
          "#c9dd9f"
        ]
      ]
    },
    visible: true
  });
  return tempSeries;
};

// Sets the X Axis parameters for the Demand Chart. In particular,
// it sets different label types depending on what time interval is selected
export const setXAxis = currentDemandSelection => {
  return [
    {
      scrollbar: {
        enabled: true,
        showFull: false
      },
      type: "datetime",
      minPadding: 0,
      maxPadding: 0,
      labels: {
        style: {
          color: "#444952",
          fontSize: 13,
          textTransform: "capitalize",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        },
        padding: 0,
        y: 25,
        formatter: function() {
          let returnValue = "";
          switch (currentDemandSelection) {
            case "halfDay":
              returnValue = getDailyLabels(this.value);
              break;
            case "threeDay":
              returnValue = getWeeklyLabels(this.value);
              break;
            case "sevenDay":
              returnValue = getWeeklyLabels(this.value);
              break;
            default:
              returnValue = "";
              break;
          }
          return returnValue;
        }
      }
    }
  ];
};

// Sets the Y Axis parameters for the Demand chart. This chart contains
// a left axis[0] (data) and right axis[1] (Outside Air Temperature).
const setYAxis = () => {
  return [
    {
      min: 0,
      lineWidth: 2,
      gridLineWidth: 1,
      title: {
        text: "kBtu/hr",
        align: "high",
        rotation: 0,
        offset: 15,
        style: {
          color: "#444952",
          fontSize: 13,
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        }
      },
      lineColor: "#F1F1F2",
      gridLineColor: "#D7D7D7",
      minPadding: 0,
      maxPadding: 0,
      opposite: false,
      tickAmount: 5,
      labels: {
        formatter: function() {
          if (this.isLast) {
            return "";
          }
          let label = this.axis.defaultLabelFormatter.call(this);
          if (/^[0-9]{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value, 0);
          }
          return label;
        },
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: 13
        },
        reserveSpace: true
      },
      endOnTick: false,
      gridZIndex: 5
    }
  ];
};

// Sets the data to values and associated dates for Highcharts
// to parse and display appropriately.
const formatData = dataArray => {
  let formattedArray = [];
  for (let i = 0; i < dataArray.length; i++) {
    let newDate = new Date(dataArray[i].Timestamp);
    if (dataArray[i].Value > 0)
      formattedArray.push([Date.parse(newDate), dataArray[i].Value]);
    else formattedArray.push([Date.parse(newDate), 0]);
  }
  return formattedArray;
};
