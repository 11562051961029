import React from "react";

import SidebarDropdown from "./dropdown/SidebarDropdown";
import AppMenu from "./appMenu/AppMenu";
import BuildingFilter from "./filter/BuildingFilter";
import GreenBuildings from "./filter/GreenBuildingFilter";
import Equation from "./equation/Equation";
import Copyright from "./copyright/Copyright";

import "./Sidebar.css";

class Sidebar extends React.Component {
  render() {
    return (
      <div className="sidebar">
        <AppMenu />
        <div className="sidebar-title">UC Davis Energy Map</div>
        <p>
          At UC Davis, we understand different buildings use different amounts
          of energy. In Facilities Energy & Engineering we're driven to
          understand our buildings so we can increase energy efficiency and help
          our campus meet its carbon neutrality goals.
        </p>
        <div className="sidebar-view">View our Energy Saving Projects:</div>
        <GreenBuildings />
        <div className="map-options">
          <SidebarDropdown />
          {/* <BuildingFilter /> */}
        </div>
        <Equation />
        <Copyright />
      </div>
    );
  }
}

export default Sidebar;
