export const opportunitiesLogQuery = `
{
  opportunitiesLog {
    buildingName
    savings
    description
    date
  }
}
`;

export const hvacSchedulingQuery = `
{
  hvacScheduling {
    buildingName
    savings
  }
}
`;

export const holidayShutdownsQuery = `
{
  holidayShutdowns {
    holiday
    startDate
    endDate
    electricitySavings
    steamSavings
    chilledWaterSavings
    totalSavings
  }
}
`;

export const aceFYSavingsQuery = `
{
  aceFYsavings {
    fyear
    savings
  }
}
`;

// NOTE: REPLACED BY HARD CODED VALUES - Nov 2024
// export const mnvTotalsQuery = `
// {
//   mnvTotal {
//     commodity
//     data {
//       currentFY {
//         fy
//         value
//       }
//       pastFY {
//         fy
//         value
//       }
//     }
//   }


// }
// `;
