/**
 * Actions used in the SeachBox component. Primary function
 * is to load the initial Facilities Link and CEED
 * collections data from GraphQL.
 */

import * as types from "./actionTypes";
import { createApolloFetch } from "apollo-fetch";
import { mapSearchQuery } from "../queries/mapSearchQuery";

// If a building is set to a CAAN number for privacy, this
// function will edit related names accordingly. This
// function also adapts buildings with a single building
// key in the CEED collection to show this name as the
// primary display in the app. (i.e. Rifle Range)
const setCeedBuildingNames = buildingArray => {
  for (let i = 0; i < buildingArray.length; i++) {
    let buildingName = buildingArray[i].buildingData[0].name,
      buildingKey = buildingArray[i].buildingData[0].bldgKey;
    if (buildingName.indexOf("CAAN") !== -1) {
      buildingArray[i].officialName = buildingName;
      buildingArray[i].primaryDisplay = buildingName;
    } else if (buildingKey !== null && buildingKey.length === 1)
      buildingArray[i].primaryDisplay = buildingName;
  }
  return sortCeedBuildings(buildingArray);
};

// Performs a resort after setCeedBuildingNames has
// completed naming convention modifications.
const sortCeedBuildings = modifiedBuildings => {
  return modifiedBuildings.sort(function(a, b) {
    let x = a.primaryDisplay.toLowerCase(),
      y = b.primaryDisplay.toLowerCase();
    if (x < y) return -1;
    if (x > y) return 1;
    return 0;
  });
};

export const loadMapSearchDataSuccess = searchData => {
  return {
    type: types.LOAD_MAP_SEARCH_DATA_SUCCESS,
    searchData
  };
};

// Retrieves CEED and Facilities Link building data, and
// splits the data into buildings with CEED data and those
// without. After splitting and CEED naming adjustments,
// it combines the arrays with CEED data appearing first.
export const loadMapSearchData = () => {
  const uri = `/api/graphql`;
  const apolloFetch = createApolloFetch({ uri });
  return function(dispatch) {
    apolloFetch({ query: mapSearchQuery })
      .then(mapData => {
        let ceedBuildings = [],
          nonCeed = [],
          fullList = [];
        for (let i = 0; i < mapData.data.buildingSearch.length; i++) {
          if (mapData.data.buildingSearch[i].buildingData.length !== 0)
            ceedBuildings.push(mapData.data.buildingSearch[i]);
          else nonCeed.push(mapData.data.buildingSearch[i]);
        }
        ceedBuildings = setCeedBuildingNames(ceedBuildings);
        fullList = ceedBuildings.concat(nonCeed);
        dispatch(loadMapSearchDataSuccess(fullList));
      })
      .catch(error => {
        console.log("ERROR", error)
      });
  };
};
