/* Contains functions for querying pi and airtable data via graphql */

import { createApolloFetch } from "apollo-fetch";
import { getTimeInterval, getReferenceTimeInterval } from "./formatting";

const fetch = createApolloFetch({
  uri: `/api/graphql`
});

/* fetch Monthly Usage data from PI using apollo-fetch */
let fetchMonthlyUsage = async (buildingName, startDate, endDate) => {
  let res = await fetch({
    query: `query {
            monthlyUsage(
                buildingId: "${buildingName}",
                commodities: ["electricity", "steam", "chilledWater"],
                startMonth: "${startDate}",
                endMonth: "${endDate}"
            ) {
                commodity,
                    data {
                    Timestamp
                    Value
                }
                rate
            }
        }`
  });
  return res.data.monthlyUsage;
};

/* fetch Monthly Baseline Usage data from PI using apollo-fetch */
let fetchMonthlyBaselineUsage = async (buildingName, startDate, endDate) => {
  let res = await fetch({
    query: `query {
            monthlyBaselineUsage(
                buildingId: "${buildingName}",
                commodities: ["electricity", "steam", "chilledWater"],
                startMonth: "${startDate}",
                endMonth: "${endDate}"
            ) {
                commodity,
                    data {
                    Timestamp
                    Value
                }
            }
        }`
  });
  return res.data.monthlyBaselineUsage;
};

export const getMonthlyUsage = async buildingName => {
  let currentDate = new Date();
  let queryInterval = getTimeInterval(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );
  let monthlyUsageData = await fetchMonthlyUsage(
    buildingName,
    queryInterval.startDate,
    queryInterval.endDate
  );

  return monthlyUsageData;
};

export const getMonthlyBaselineUsage = async buildingName => {
  let currentDate = new Date();
  let queryInterval = getTimeInterval(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );
  let monthlyBaselineData = await fetchMonthlyBaselineUsage(
    buildingName,
    queryInterval.startDate,
    queryInterval.endDate
  );

  return monthlyBaselineData;
};

/*
  Reference Period Functions for Nico's team -> to be used at a later date
*/
export const getMonthlyReferenceUsage = async buildingName => {
  let queryInterval = getReferenceTimeInterval();
  let monthlyUsageData = await fetchMonthlyUsage(
    buildingName,
    queryInterval.startDate,
    queryInterval.endDate
  );

  return monthlyUsageData;
};

export const getMonthlyBaselineReferenceUsage = async buildingName => {
  let queryInterval = getReferenceTimeInterval();
  let monthlyBaselineData = await fetchMonthlyBaselineUsage(
    buildingName,
    queryInterval.startDate,
    queryInterval.endDate
  );

  return monthlyBaselineData;
};
