import React from "react";
import PropTypes from "prop-types";
import buildingCommIcon from "../images/Commissioning.svg";
import opportunitiesIcon from "../images/Opportunities.svg";
import hvacIcon from "../images/HVAC-Scheduling.svg";
import holidayIcon from "../images/HolidayShutdowns.svg";
import { formatDollarValue } from "../../../common/utilities";
import "./TabNavigation.css";

const TabNavigation = ({
  tabSelection,
  onClick,
  buildingCommTotal,
  totals
}) => {
  const tabNavigationClass = property => {
    let baseClass = "tab-navigation-button " + property;
    if (property === tabSelection) return baseClass + " active";
    return baseClass;
  };
  let FYTotals = {};
  // let data = totals.currentFYtotals.mnvTotals.mnvTotal;
  // Note: replaced by hardcoded values - Nov 2024
  let data = [
  {
    commodity: "Holiday Total", // Holiday Shutdown
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value:  48608
      }
    }
  },
  {
    commodity: "Opportunity Total", // Opportunities Log
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value:  604393
      }
    }
  },
  {
    commodity: "Hvac Total", // HVAC Scheduling
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 158229
      }
    }
  },
  {
    commodity: "Building Commission Total", // Building Projects
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 1145842
      }
    }
  },
  {
    commodity: "Other Projects", // Other Projects
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 302794
      }
    }
  },
  {
    commodity: "Total Savings", // Total
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 2259866
      }
    }
  },
  // {
  //   commodity: "Forecasted Savings", // TODO: find tag
  //   data: {
  //     pastFY: {
  //       fy: 2023,
  //       value: 0 // TODO: add value
  //     },
  //     currentFY: {
  //       fy: 2024,
  //       value: 2259866
  //     }
  //   }
  // },
];
  for (let i = 0; i < data.length; i++) {
    // let CommodityWord = totals.currentFYtotals.mnvTotals.mnvTotal[i].commodity;
    let CommodityWord = data[i].commodity;
    let Commodity = CommodityWord.split(" ")[0];
    // FYTotals[Commodity] = totals.currentFYtotals.mnvTotals.mnvTotal[i].data.pastFY;
    FYTotals[Commodity] = data[i].data.currentFY;
  }
  return (
    <React.Fragment>
      <div className="tab-wrap">
        <div
          className={tabNavigationClass("buildingCommissioning")}
          id="buildingCommissioning"
          onClick={onClick}
        >
          <img
            src={buildingCommIcon}
            alt="Building Commissioning icon"
            id="buildingCommissioning"
          />
          <span className="tab-title" id="buildingCommissioning">
            Building Commissioning
          </span>
          <span className="tab-FY">Savings for FY{FYTotals.Building.fy}: </span>
          <b id="buildingCommissioning">
            {formatDollarValue(FYTotals.Building.value)}
          </b>
        </div>
        {/* <div
          className={tabNavigationClass("opportunitiesLog")}
          id="opportunitiesLog"
          onClick={onClick}
        >
          <img
            src={opportunitiesIcon}
            alt="Building Commissioning icon"
            id="opportunitiesLog"
          />
          <span className="tab-title" id="opportunitiesLog">
            Opportunities Log
          </span>
          <span className="tab-FY">
            Savings for FY{FYTotals.Opportunity.fy}:{" "}
          </span>
          <b id="opportunitiesLog">
            {formatDollarValue(FYTotals.Opportunity.value)}
          </b>
        </div>
        <div className={tabNavigationClass("hvac")} id="hvac" onClick={onClick}>
          <img src={hvacIcon} alt="Building Commissioning icon" id="hvac" />
          <span className="tab-title" id="hvac">
            HVAC Scheduling
          </span>
          <span className="tab-FY">Savings for FY{FYTotals.Holiday.fy}: </span>
          <b id="hvac">{formatDollarValue(FYTotals.Hvac.value)}</b>
        </div>
        <div
          className={tabNavigationClass("holidayShutdowns")}
          id="holidayShutdowns"
          onClick={onClick}
        >
          <img
            src={holidayIcon}
            alt="Building Commissioning icon"
            id="holidayShutdowns"
          />
          <span className="tab-title" id="holidayShutdowns">
            Holiday Shutdowns
          </span>
          <span className="tab-FY">Savings for FY{FYTotals.Holiday.fy}: </span>
          <b id="holidayShutdowns">
            {formatDollarValue(FYTotals.Holiday.value)}
          </b>
        </div> */}
      </div>
    </React.Fragment>
  );
};

TabNavigation.propTypes = {
  totals: PropTypes.object.isRequired,
  tabSelection: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default TabNavigation;
