import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  TileLayer,
  ZoomControl,
  AttributionControl,
  Map as ReactLeafletMap
} from "react-leaflet";

import BuildingMarker from "./BuildingMarker";
import Logo from "./logo/Logo";

import "./Map.css";
import "leaflet/dist/leaflet.css";

import { bindActionCreators } from "redux";
import * as markerClickActions from "../../../actions/markerClickActions";
import * as filterActions from "../../../actions/filterActions";

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapLocation: {
        lat: 38.538,
        lng: -121.756,
        zoom: 16
      }
    };
  }

  componentDidMount() {
    if(window.location.pathname === "/ucdhealth") {
      this.setState({
        mapLocation: {
          lat: 38.551186,
          lng: -121.451299,
          zoom: 16.4
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (window.location.pathname !== "/ucdhealth" && this.state.mapLocation !== this.props.mapLocation) {
      this.setState(prevState => {
        return {
          mapLocation: this.props.mapLocation
        };
      });
    }
  }

  render() {
    const position = [this.state.mapLocation.lat, this.state.mapLocation.lng];
    const mapboxUrl = `/api/mapbox/{z}/{x}/{y}`;

    const mapAttribution = `&copy; <a href="https://www.mapbox.com/">Mapbox</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`;

    const resetMarkerClick = () => {
      let markerClickStatus = false;
      let buildingData = null;
      this.props.markerClickActions.markerClickHaveCost(
        markerClickStatus,
        buildingData
      );
    };
    return (
      <React.Fragment>
        <ReactLeafletMap
          center={position}
          zoom={this.state.mapLocation.zoom}
          zoomControl={false}
          attributionControl={false}
          closePopupOnClick={false}
          onClick={e => {
            resetMarkerClick();
            e.target.closePopup();
          }}
        >
          <TileLayer
            attribution={mapAttribution}
            url={mapboxUrl}
            tileSize={512}
            zoomOffset={-1}
            crossOrigin={true}
          />
          {this.props.mapData
            .filter(marker => {
              return marker.valueVisible && marker.buildingTypeVisible;
            })
            .map((marker, index) => (
              <BuildingMarker
                greenButton={this.props.greenButton}
                marker={marker}
                index={index}
                key={index}
              />
            ))}
          <AttributionControl position="bottomright" prefix="" />
          <ZoomControl position="topright" />
        </ReactLeafletMap>
        <Logo />
      </React.Fragment>
    );
  }
}

Map.propTypes = {
  mapData: PropTypes.array.isRequired,
  mapLocation: PropTypes.object.isRequired,
  greenButton: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    mapData: state.mapData,
    mapLocation: state.mapLocation,
    greenButton: state.greenButton
  };
};

const mapDispatchToProps = dispatch => {
  return {
    markerClickActions: bindActionCreators(markerClickActions, dispatch),
    greenButtonActions: bindActionCreators(filterActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
