import React from "react";

// eui filter (range slider)
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

import BuildingTypeButtons from "./BuildingTypeButtons";

// redux actions, store
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as mapDataActions from "../../../../actions/mapDataActions";
import * as filterActions from "../../../../actions/filterActions";

class BuildingFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterSlider: {
        values: [],
        maxValue: 0
      },
      currentMapSelection: "eui"
    };
    this.updateValue = this.updateValue.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.filterSlider !== nextProps.filterSlider) {
      this.setState(prevState => {
        return { filterSlider: nextProps.filterSlider };
      });
    }
    if (this.state.currentMapSelection !== nextProps.currentMapSelection) {
      this.setState(prevState => {
        return { currentMapSelection: nextProps.currentMapSelection };
      });
      this.props.filterActions.setSliderValues(
        nextProps.filterSlider,
        nextProps.mapData,
        nextProps.currentMapSelection
      );
    }
    if (nextProps.filterSlider.maxValue === 0 && nextProps.mapData.length > 0)
      this.props.filterActions.setSliderValues(
        nextProps.filterSlider,
        nextProps.mapData,
        this.state.currentMapSelection
      );
  }

  updateValue(sliderState) {
    this.filterValues(sliderState.values, this.props.mapData);
    this.props.filterActions.sliderValueChange(
      this.state.filterSlider,
      sliderState.values
    );
  }

  filterValues(values, mapData) {
    let minValue = values[0];
    let maxValue = values[1];
    let newMapData = [];
    mapData.forEach(marker => {
      if (minValue > 0 && marker.euiReady === false) {
        marker.valueVisible = false;
      } else if (
        marker[this.props.currentMapSelection] < minValue ||
        marker[this.props.currentMapSelection] > maxValue
      ) {
        marker.valueVisible = false;
      } else {
        marker.valueVisible = true;
      }
      newMapData.push(marker);
    });
    this.props.mapActions.updateMapData(newMapData);
  }

  render() {
    return (
      <div className="building-filter">
        {/* commented out because slider doesn't work */}
        {/* <div className="filter-slider-wrapper">
          <div className="filter-slider-title">
            Filter by{" "}
            {this.props.currentMapSelection === "eui" ? "EUI" : "Usage"}:
          </div>
          {this.props.filterSlider.maxValue > 0 ? (
            <div className="filter-slider">
              <div className="slider-values">
                <span>
                  {this.props.filterSlider.values[0].toLocaleString("en", {
                    useGrouping: true
                  })}{" "}
                  kBtu/{this.props.currentMapSelection === "eui" ? "sf" : "yr"}
                </span>
                <span>
                  {this.props.filterSlider.values[1].toLocaleString("en", {
                    useGrouping: true
                  })}{" "}
                  kBtu/{this.props.currentMapSelection === "eui" ? "sf" : "yr"}
                </span>
              </div>
              <RangeSlider
                min={0}
                max={this.props.filterSlider.maxValue}
                value={this.props.filterSlider.values}
                onInput={this.updateValue}
              />
            </div>
          ) : null}
        </div> */}
        <BuildingTypeButtons />
      </div>
    );
  }
}

BuildingFilter.propTypes = {
  mapData: PropTypes.array.isRequired,
  filterSlider: PropTypes.object.isRequired,
  currentMapSelection: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    mapData: state.mapData,
    filterSlider: state.filterSlider,
    currentMapSelection: state.currentMapSelection
  };
};

const mapDispatchToProps = dispatch => {
  return {
    mapActions: bindActionCreators(mapDataActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingFilter);
