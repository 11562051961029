import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as waterGoalsChartOptions from "./WaterGoalsChartOptions";
import downloadHoverIcon from "../../../common/images/download_csv.png";
import downloadIcon from "../../../common/images/download_csv_hover.png";

import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more.src.js";
import WaterGoalsGraph from "./WaterGoalsGraph";
import WaterGoalsCsv from "./WaterGoalsCsv";
import WaterGoalsStory from "./WaterGoalsStory";
HighchartsMore(Highcharts);


let waterBarChart = null;

const SOURCES = [
  {
    value: "normalizedDomesticWaterUsage",
    displayName: "Dometic Water",
    color: "#336CC8"
  },
  {
    value: "normalizedUtilityWaterUsage",
    displayName: "Utility Water",
    color: "#66D1EE"
  }
]

function WaterGoals(props) {
  const [checkBoxes, setCheckBoxes] = useState({
    normalizedDomesticWaterUsage: true,
    normalizedUtilityWaterUsage: true,
  })
  const [chartData, setChartData] = useState({
    xAxis : [],
    data: []
  })
  const [csvIcon, setCsvIcon] = useState(downloadHoverIcon)

  const updateGraphOptions = (event) => {
    let selection = event.target.id;
    let newCheckBoxes = Object.assign({}, checkBoxes);
    newCheckBoxes[selection] = !newCheckBoxes[selection];
    setCheckBoxes(newCheckBoxes)
    updateGraphView(selection);
  }

  const updateGraphView = (checkBox) => {
    if (checkBox === undefined) {
      waterBarChart.destroy();
      waterBarChart = Highcharts.StockChart(
        "water-goals-chart",
        waterGoalsChartOptions.initializeEnergyGraph(
          chartData.xAxis,
          chartData.data
        )
      );
    } else {
      for (let i = 0; i < chartData.data.length; i++)
        if (chartData.data[i].id === checkBox)
          chartData.data[i].visible = !chartData.data[i].visible;
      waterBarChart.update({ series: chartData.data });
      setChartData(chartData);
    }
  }

  function buildWaterChart() {
    if(props.waterGoalsData.length > 0) {
      let goals = [],
        xAxis = []
      for (let i = 0; i < SOURCES.length; i++)
        goals.push(
          waterGoalsChartOptions.energySource(
            SOURCES[i].value,
            SOURCES[i].displayName,
            SOURCES[i].color,
            checkBoxes[SOURCES[i].value]
          )
        );
      let waterGoalsData = props.waterGoalsData.sort((a,b) => a.year - b.year)
      for (let x = 0; x < waterGoalsData.length; x++) {
        let data = waterGoalsData[x].usage;
        if(waterGoalsData[x].year == "2005") {
          xAxis.push("FY05/06, 06/07, 07/08 Average")
        } else {
          xAxis.push("FY "+waterGoalsData[x].year+"/"+(Number(waterGoalsData[x].year.slice(2,))+1).toString())
        }
        for (let y = 0; y < goals.length; y++){
          goals[y].data.push(data[goals[y].id])
        }
      }
      let newChartData = Object.assign({}, chartData);
      newChartData.data = goals;
      newChartData.xAxis = xAxis;
      setChartData(newChartData);
      waterBarChart = Highcharts.StockChart(
        "water-goals-chart",
        waterGoalsChartOptions.initializeEnergyGraph(xAxis, goals, xAxis.length)
      );
    }
  }

  useEffect(() => {
    if(props.waterGoalsData) {
      buildWaterChart();
    }
  }, [props.waterGoalsData])

  return (
    <div className="water-goals water-card">
      <div className="water-headline">
        We are currently working towards our 2025 water usage target.
        As of 2023/24 we had reached 34% reduction in campus potable water usage.
        Find out <a href="https://sustainability.ucdavis.edu/goals/water#goals" target="_blank" style={{ textDecoration: 'underline' }}>more</a> about what we've done to save water.
      </div>
      <div className="water-graph">
          <div className="water-chart">
            <div id="water-goals-chart" />
          </div>
          <div className="water-chart-selection">
            <WaterGoalsGraph
              onClick={updateGraphOptions}
              options={checkBoxes}
              sources={SOURCES}
            />
            <div
              className="csv-div"
              onMouseOver={() => {
                setCsvIcon(downloadIcon);
              }}
              onMouseOut={() => {
                setCsvIcon(downloadHoverIcon);
              }}
            >
              {props.waterGoalsData && <WaterGoalsCsv
                data={props.waterGoalsData}
                sources={SOURCES}
                icon={csvIcon}
              />}
            </div>
          </div>
        </div>
      <WaterGoalsStory />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    waterGoalsData: state.waterGoal.waterGoalData
  }
}

export default connect(mapStateToProps)(WaterGoals)

