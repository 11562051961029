import * as types from "../actionTypes";
import { createApolloFetch } from "apollo-fetch";
import * as chcpQueries from "../../queries/chcpQuery";
import * as chcpUtils from "./chcpUtilities";

const uri = `/api/graphql`;
const apolloFetch = createApolloFetch({ uri });

const ANNUAL_PROD_TOTAL_TAGS = [
    "Boiler_Plant.Annual_Steam_Production_MMBtu",
    "Chiller_Plants.Annual_Chilled_Water_Production_MMBtu"
  ],
  MONTHLY_PROD_TOTAL_TAGS = [
    "Boiler_Plant.Monthly_Steam_Production_MMBtu",
    "Chiller_Plants.Monthly_Chilled_Water_Production_MMBtu"
  ],
  DEMAND_PROD_TAGS = [
    "Boiler_1.Steam_Flow_Rate_MMBtu/hr",
    "Boiler_2.Steam_Flow_Rate_MMBtu/hr",
    "Boiler_3.Steam_Flow_Rate_MMBtu/hr",
    "Boiler_4.Steam_Flow_Rate_MMBtu/hr",
    "CHCP.Chilled_Water_Production_MMBtu/hr",
    "TES.Chilled_Water_Production_MMBtu/hr"
  ],
  USAGE_PROD_TAGS = [
    "Boiler_1.Monthly_Steam_Production_MMBtu",
    "Boiler_2.Monthly_Steam_Production_MMBtu",
    "Boiler_3.Monthly_Steam_Production_MMBtu",
    "Boiler_4.Monthly_Steam_Production_MMBtu",
    "CHCP.Monthly_Chilled_Water_Production_MMBtu",
    "TES.Monthly_Chilled_Water_Production_MMBtu"
  ];

const chcpProductionSuccess = chcpProduction => {
  return {
    type: types.LOAD_CHCP_PRODUCTION_SUCCESS,
    chcpProduction
  };
};

export const loadProductionTotal = () => {
  let dates = chcpUtils.getDateFormats();
  return function(dispatch) {
    apolloFetch({
      query: chcpQueries.productionTotal,
      variables: {
        currentTime: dates.currentMonth,
        productionStart: dates.lastYearMonth,
        annualTags: ANNUAL_PROD_TOTAL_TAGS,
        monthlyTags: MONTHLY_PROD_TOTAL_TAGS
      }
    }).then(async chcpProductionData => {
      if (chcpProductionData.data !== undefined) {
        let annual = await splitTotalData(chcpProductionData.data.totalAnnual),
          monthly = await splitTotalData(chcpProductionData.data.totalMonthly),
          steam = {
            monthly: monthly["steam"],
            annual: annual["steam"]
          },
          chilledWater = {
            monthly: monthly["chilledWater"],
            annual: annual["chilledWater"]
          };
        dispatch(
          chcpProductionSuccess({
            totalProduction: {
              steam,
              chilledWater
            }
          })
        );
      } else
        console.log(
          "A Production data error occurred. Please refresh your page."
        );
    });
  };
};

export const loadProductionRealTime = () => {
  let dates = chcpUtils.getDateFormats(),
    variables = {
      currentTime: dates.currentTime,
      productionStart: dates.tagStart,
      dailyStart: dates.yesterday,
      weeklyStart: dates.weekAgo,
      monthlyStart: dates.monthAgo,
      demandTags: DEMAND_PROD_TAGS,
      usageTags: USAGE_PROD_TAGS
    };
  return function(dispatch) {
    let daily = processDemandQuery(
        { query: chcpQueries.productionDaily, variables },
        "daily"
      ),
      weekly = processDemandQuery(
        { query: chcpQueries.productionWeekly, variables },
        "weekly"
      ),
      monthly = processDemandQuery(
        { query: chcpQueries.productionMonthly, variables },
        "monthly"
      ),
      historic = processUsageQuery(
        { query: chcpQueries.productionHistoric, variables },
        "historic"
      );
    Promise.all([daily, weekly, monthly, historic]).then(values => {
      let finalData = {
        daily: {},
        weekly: {},
        monthly: {},
        historic: {}
      };
      for (let finalKey in finalData)
        for (let i = 0; i < values.length; i++)
          if (finalKey === values[i].label)
            finalData[finalKey] = values[i].data;
      dispatch(
        chcpProductionSuccess({
          realTimeProduction: finalData
        })
      );
    });
  };
};

const processDemandQuery = (query, label) => {
  return new Promise((resolve, reject) => {
    apolloFetch(query).then(async chcpDemandData => {
      let formatData = {
        commodities: ["chilledWater", "steam"],
        steam: {
          total: [],
          boiler1: [],
          boiler2: [],
          boiler3: [],
          boiler4: []
        },
        chilledWater: {
          total: [],
          chiller1: [],
          chiller2: []
        }
      };
      let rawDemandData = [];
      for (let key in chcpDemandData.data)
        rawDemandData = chcpDemandData.data[key];
      for (let i = 0; i < rawDemandData.length; i++) {
        if (rawDemandData[i].commodity.indexOf(DEMAND_PROD_TAGS[0]) !== -1)
          formatData.steam.boiler1 = rawDemandData[i].data;
        else if (rawDemandData[i].commodity.indexOf(DEMAND_PROD_TAGS[1]) !== -1)
          formatData.steam.boiler2 = rawDemandData[i].data;
        else if (rawDemandData[i].commodity.indexOf(DEMAND_PROD_TAGS[2]) !== -1)
          formatData.steam.boiler3 = rawDemandData[i].data;
        else if (rawDemandData[i].commodity.indexOf(DEMAND_PROD_TAGS[3]) !== -1)
          formatData.steam.boiler4 = rawDemandData[i].data;
        else if (rawDemandData[i].commodity.indexOf(DEMAND_PROD_TAGS[4]) !== -1)
          formatData.chilledWater.chiller1 = rawDemandData[i].data;
        else if (rawDemandData[i].commodity.indexOf(DEMAND_PROD_TAGS[5]) !== -1)
          formatData.chilledWater.chiller2 = rawDemandData[i].data;
      }
      formatData.steam.total = await chcpUtils.getCommodityTotal(
        formatData.steam
      );
      formatData.chilledWater.total = await chcpUtils.getCommodityTotal(
        formatData.chilledWater
      );
      resolve({ label, data: formatData });
    });
  });
};

const processUsageQuery = (query, label) => {
  return new Promise((resolve, reject) => {
    apolloFetch(query).then(async chcpUsageData => {
      let formatData = {
        commodities: ["chilledWater", "steam"],
        steam: {},
        chilledWater: {}
      };
      let rawUsageData = [];
      for (let key in chcpUsageData.data)
        rawUsageData = chcpUsageData.data[key];
      for (let i = 0; i < rawUsageData.length; i++) {
        if (rawUsageData[i].commodity.indexOf(USAGE_PROD_TAGS[0]) !== -1)
          formatData.steam.boiler1 = rawUsageData[i].data;
        else if (rawUsageData[i].commodity.indexOf(USAGE_PROD_TAGS[1]) !== -1)
          formatData.steam.boiler2 = rawUsageData[i].data;
        else if (rawUsageData[i].commodity.indexOf(USAGE_PROD_TAGS[2]) !== -1)
          formatData.steam.boiler3 = rawUsageData[i].data;
        else if (rawUsageData[i].commodity.indexOf(USAGE_PROD_TAGS[3]) !== -1)
          formatData.steam.boiler4 = rawUsageData[i].data;
        else if (rawUsageData[i].commodity.indexOf(USAGE_PROD_TAGS[4]) !== -1)
          formatData.chilledWater.chiller1 = rawUsageData[i].data;
        else if (rawUsageData[i].commodity.indexOf(USAGE_PROD_TAGS[5]) !== -1)
          formatData.chilledWater.chiller2 = rawUsageData[i].data;
      }
      formatData.years = chcpUtils.processYears(
        formatData.steam.boiler1.length >=
          formatData.chilledWater.chiller1.length
          ? formatData.steam.boiler1
          : formatData.chilledWater.chiller1
      );
      resolve({ label, data: formatData });
    });
  });
};

const splitTotalData = totalData => {
  let tempSteam = 0,
    tempChilledWater = 0;
  for (let i = 0; i < totalData.length; i++) {
    for (let j = 0; j < totalData[i].data.length; j++) {
      if (totalData[i].commodity.toLowerCase().indexOf("steam") !== -1)
        tempSteam += totalData[i].data[j].Value;
      else tempChilledWater += totalData[i].data[j].Value;
    }
    if (
      totalData[i].commodity.toLowerCase().indexOf("steam") !== -1 &&
      totalData[i].data.length > 1
    )
      tempSteam = tempSteam / totalData[i].data.length;
    else if (totalData[i].data.length > 1)
      tempChilledWater = tempChilledWater / totalData[i].data.length;
  }
  return {
    steam: Math.round(tempSteam),
    chilledWater: Math.round(tempChilledWater)
  };
};
