import React, { Component } from "react";
import MediaQuery from "react-responsive";
import Header from "./header/Header";
import Navigation from "./navigation/Navigation";
import TotalSavings from "./totalSavings/TotalSavings";
import BuildingProjects from "./buildingProjects/BuildingProjects";
// import SavingCalculator from "./savingCalculator/SavingCalculator";
import Footer from "../building/desktop/footer/Footer";
import MobileComingSoon from "../mobileComingSoon/MobileComingSoon";
import PropTypes from "prop-types";
import { breakPoints } from "../common/config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as savingsAction from "../../actions/buildingActions";
import * as projectsActions from "../../actions/energySaving/buildingProjectsActions";
import "./EnergySaving.css";

const DEFAULT_NAVIGATION_TAG = "totalSavings";
const buildingTypeOptions = ["classroom", "office", "lab", "community"];

const SetNavigationView = props => {
  switch (props.view) {
    case "totalSavings":
      return <TotalSavings />;
    case "buildingProjects":
      return <BuildingProjects />;
    // case "savingsCalculator":
    //   return (
    //     <SavingCalculator
    //       savings={props.savings}
    //       buildingActions={props.buildingActions}
    //     />
    //   );
    default:
      return null;
  }
};

class EnergySaving extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentNavigation: DEFAULT_NAVIGATION_TAG,
      // savings: {
      //   classroom: [],
      //   office: [],
      //   lab: [],
      //   community: []
      // }
    };
  }

  async componentDidMount() {
    await this.props.buildingProjectsActions.loadBuildingsProjectsData();
    for (let i = 0; i < buildingTypeOptions.length; i++) {
      await this.props.actions.loadSavingsCalculatorData(
        buildingTypeOptions[i]
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.savings !== this.props.savings) {
      let buildingType = this.props.savings[0]["buildingType"];
      for (let i = 0; i < buildingTypeOptions.length; i++) {
        if (buildingTypeOptions[i] === buildingType) {
          this.setState({
            savings: {
              ...this.state.savings,
              [buildingTypeOptions[i]]: this.props.savings
            }
          });
        }
      }
    }
  }

  updateNavigation = event => {
    let newValue = event.target.id;
    this.setState({
      currentNavigation: newValue
    });
  };

  render() {
    return (
      <React.Fragment>
        <MediaQuery minWidth={breakPoints.tablet}>
          <div className="saving">
            <Header />
            <Navigation
              selection={this.state.currentNavigation}
              onClick={this.updateNavigation}
            />
            <div className="saving-content">
              <SetNavigationView
                view={this.state.currentNavigation}
                // savings={this.state.savings}
                // buildingActions={this.props.actions}
              />
            </div>
            <Footer />
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={breakPoints.mobile}>
          <MobileComingSoon />
        </MediaQuery>
      </React.Fragment>
    );
  }
}

EnergySaving.propTypes = {
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    savings: state.savingsCalculator
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(savingsAction, dispatch),
    buildingProjectsActions: bindActionCreators(projectsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EnergySaving);
