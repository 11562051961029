export const buildingInformation = `
query buildingInformation($id: String!) {
	information: building(
		id: $id
	) {
		id
		name
		buildingType
		euiReady
		hasImage
		primaryUse
		primaryPercent
		energyInfo {
			commodities
      hotWaterConversion
			tags {
				demand {
					chilledWater {
						active
					}
					electricity {
						active
					}
					steam {
						active
					}
					gas {
						active
					}
					solar {
						active
					}
				}
				annualUsage {
					chilledWater {
						active
					}
					electricity {
						active
					}
					steam {
						active
					}
					gas {
						active
					}
					solar {
						active
					}
				}
				monthlyUsage {
					chilledWater {
						active
					}
					electricity {
						active
					}
					steam {
						active
					}
					gas {
						active
					}
					solar {
						active
					}
				}
			}
		}
		facLinkData {
			relatedGross
			constructed
		}
	}
}
`;

export const demandDaily = `
query buildingDemandDaily($id: String!, $commodities:[String]!, $currentTime:String!, $demandDailyStart:String!) {
  demandDaily : demand(
    buildingId: $id
    commodities: $commodities
    startTime: $demandDailyStart
    endTime: $currentTime
    interval:"5m"
  ) {
		building
    commodity
    data {
      Timestamp
      Value
    }
  },
  oatDaily : interpolatedOat(
    startTime: $demandDailyStart
    endTime: $currentTime
    interval:"5m"
  ) {
      Timestamp
      Value
  }
}
`;

export const demandWeekly = `
query buildingDemandWeekly($id: String!, $commodities:[String]!, $currentTime:String!, $demandWeeklyStart:String!) {
  demandWeekly : demand(
    buildingId: $id
    commodities: $commodities
    startTime: $demandWeeklyStart
    endTime: $currentTime
    interval:"15m"
  ) {
		building
    commodity
    data {
      Timestamp
      Value
    }
  },
  oatWeekly : interpolatedOat(
    startTime: $demandWeeklyStart
    endTime: $currentTime
    interval:"15m"
  ) {
      Timestamp
      Value
  }
}
`;

export const demandWeeklyTLC = `
query DemandWeeklyTLC($commodities:[String]!, $currentTime:String!, $demandWeeklyStart:String!) {
  demandWeeklyTLC : demandTLC(
    buildingId: "tlc"
    commodities: $commodities
    startTime: $demandWeeklyStart
    endTime: $currentTime
    interval:"15m"
  ) {
		building
    commodity
    data {
      Timestamp
      Value
    }
  },
  oatWeekly : interpolatedOat(
    startTime: $demandWeeklyStart
    endTime: $currentTime
    interval: "15m"
  ) {
      Timestamp
      Value
  }
}
`;

export const demandMonthly = `
query buildingDemandMonthly($id: String!, $commodities:[String]!, $currentTime:String!, $demandMonthlyStart:String!) {
  demandMonthly : demand(
    buildingId: $id
    commodities: $commodities
    startTime: $demandMonthlyStart
    endTime: $currentTime
    interval:"60m"
  ) {
		building
    commodity
    data {
      Timestamp
      Value
    }
  },
  oatMonthly : interpolatedOat(
    startTime: $demandMonthlyStart
    endTime: $currentTime
    interval:"60m"
  ) {
      Timestamp
      Value
  }
}
`;

export const singleBuildingEuis = `
query buildingEuiValues($id: String!, $commodities:[String]!) {
  singleBuildingEuis(
    buildingId: $id
    commodities: $commodities
  ) {
    eui
    commodity
  }
}`;

export const monthlyUsage = `
query buildingUsageMonthly($id: String!, $commodities:[String]!, $usageMonthlyEnd:String!) {
	monthlyUsage(
		buildingId: $id
		commodities: $commodities
		startMonth: "2013-02"
		endMonth: $usageMonthlyEnd
	) {
		building
    commodity
		rate
    data {
      Timestamp
      Month
      Value
    }
  },
    monthlyOat(
      startMonth:"2013-02",
      endMonth: $usageMonthlyEnd
    ) {
      tempType,
      data {
        Timestamp
        Value
        Month
      }
    }
}
`;

// TODO:  IN MAP.JS - failed to fetch webid for some buildings
export const allBuildingEuis = `
query euiData($commodity:String!) {
	euiData : allEuisByCommodity(
		commodity: $commodity
	) {
    commodity
    euis {
      building
      eui
    }
  }
}
`;

export const rankingPercents = `
query {
  rankingPercents {
    building
    name
    domesticWaterPercent
    electricityPercent
  }
}
`;

export const rankingWeekly = `
	query rankingWeekly($id: String!, $weeks:Int) {
		rankingWeekly(buildingId: $id, weeks: $weeks) {
			electricity {
				Timestamp
				Value
			}
			domesticWater {
				Timestamp
				Value
			}
		}
	}
`;

export const savingsCalculator = `
  query savingsCalculator($typeOfBuilding: String!){
    savingsCalculator(buildingType: $typeOfBuilding){
      time
      holiday
      building
      before
      during
      percent
      commodity
      buildingType
      season
    }
  }
`;
