import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as moment from "moment";
import * as utilities from "../../common/utilities";
import TotalSavingsChart from "./TotalSavingsChart";
import CurrentSavings from "./currentSavings/CurrentSavings";
import "./TotalSavings.css";

const COMMODITY_TYPES = [
  "Holiday Total",
  "Opportunity Total",
  "Hvac Total",
  "Building Commission Total",
  "Other Projects"
];

const CURRENT_FY = utilities.getCurrFiscalYear();

const PROJECT_TYPES = {
  "Building Commission Total": "buildingCommissioning",
  "Opportunity Total": "opportunitiesLog",
  "Hvac Total": "hvacScheduling",
  "Holiday Total": "holidayShutdowns",
  "Other Projects": "otherProjects"
};

class TotalSavings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fiscalYears: [],
      annualSavings: {},
      total: 0,
      currentFySavings: {
        buildingCommissioning: 0,
        opportunitiesLog: 0,
        hvacScheduling: 0,
        holidayShutdowns: 0,
        otherProjects: 0
      },
      currentFyProjected: 0,
      currentFyTotal: 0
    };
  }

  componentDidMount() {
    if (this.props.projects.holidayShutdowns.length > 0)
      this.calculateAnnualSavings();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.projects) !== JSON.stringify(this.props.projects)
    )
      this.calculateAnnualSavings();
  }

  calculateAnnualSavings() {
    let annualSavings = this.createPastSaving(),
      total = 0;
    let fiscalYears = this.getFiscalYearsList();
    let currentFySavings = this.createCurrentFiscalYearSavings(),
      currentFyTotal = this.createTotalFYSavings(currentFySavings),
      currentFyProjected = this.getProjectedSavings();
    annualSavings[CURRENT_FY] = currentFyProjected;
    for (let key in annualSavings) total += annualSavings[key];
    this.setState({
      annualSavings,
      currentFySavings,
      currentFyProjected,
      currentFyTotal,
      fiscalYears,
      total
    });
  }

  getFiscalYearsList() {
    let fiscalYears = [];
    let savingsData = this.props.projects.aceFYsavings.aceFYsavings;
    for (let i = 0; i < savingsData.length; i++) {
      fiscalYears.push(savingsData[i].fyear);
    }
    fiscalYears.push(CURRENT_FY);
    return fiscalYears.sort(
      (a, b) => parseInt(a.slice(-2), 10) - parseInt(b.slice(-2), 10)
    );
  }

  createPastSaving() {
    let annualSavingsObj = {};

    let savingsData = this.props.projects.aceFYsavings.aceFYsavings;
    for (let i = 0; i < savingsData.length; i++) {
      let year = savingsData[i].fyear;
      let savings = savingsData[i].savings;
      annualSavingsObj[year] = parseInt(savings);
    }
    return annualSavingsObj;
  }

  createCurrentFiscalYearSavings() {
    // let mnvTot = this.props.projects.currentFYtotals.mnvTotals.mnvTotal;
    let mnvTot = [
  {
    commodity: "Holiday Total", // Holiday Shutdown
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value:  48608
      }
    }
  },
  {
    commodity: "Opportunity Total", // Opportunities Log
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value:  604393
      }
    }
  },
  {
    commodity: "Hvac Total", // HVAC Scheduling
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 158229
      }
    }
  },
  {
    commodity: "Building Commission Total", // Building Projects
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 1145842
      }
    }
  },
  {
    commodity: "Other Projects", // Other Projects
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 302794
      }
    }
  },
  {
    commodity: "Total Savings", // Total
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 2259866
      }
    }
  },
  // {
  //   commodity: "Forecasted Savings", // TODO: find tag
  //   data: {
  //     pastFY: {
  //       fy: 2023,
  //       value: 0 // TODO: add value
  //     },
  //     currentFY: {
  //       fy: 2024,
  //       value: 2259866
  //     }
  //   }
  // },
];
    let tempObj = {};
    for (let i = 0; i < mnvTot.length; i++) {
      if (COMMODITY_TYPES.includes(mnvTot[i].commodity)) {
        let index = PROJECT_TYPES[mnvTot[i].commodity];
        tempObj[index] = mnvTot[i].data.currentFY.value;
      }
    }
    return tempObj;
  }

  createTotalFYSavings(currentFySavings) {
    let totalSavings = 0;
    for (let item in currentFySavings) {
      totalSavings += currentFySavings[item];
    }
    return totalSavings;
  }

  getProjectedSavings() {
    // let mnvTot = this.props.projects.currentFYtotals.mnvTotals.mnvTotal;
    // NOTE: replaced by hardcoded values - Nov 2024
    let mnvTot = [
  {
    commodity: "Holiday Total", // Holiday Shutdown
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value:  48608
      }
    }
  },
  {
    commodity: "Opportunity Total", // Opportunities Log
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value:  604393
      }
    }
  },
  {
    commodity: "Hvac Total", // HVAC Scheduling
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 158229
      }
    }
  },
  {
    commodity: "Building Commission Total", // Building Projects
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 1145842
      }
    }
  },
  {
    commodity: "Other Projects", // Other Projects
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 302794
      }
    }
  },
  {
    commodity: "Total Savings", // Total
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 2259866
      }
    }
  },
  // {
  //   commodity: "Forecasted Savings", // TODO: find tag
  //   data: {
  //     pastFY: {
  //       fy: 2023,
  //       value: 0 // TODO: add value
  //     },
  //     currentFY: {
  //       fy: 2024,
  //       value: 2259866
  //     }
  //   }
  // },
];
    let value = 0;
    for (let i = 0; i < mnvTot.length; i++) {
      if (mnvTot[i].commodity === "Forecasted Savings") {
        let value = mnvTot[i].data.currentFY.value;
        return value;
      }
    }
  }

  render() {
    return (
      <div className="total-savings standard-card">
        <div className="total-savings-description">
          Our team's funding structure is set up so that the verified energy
          savings from our projects are re-directed to fund our team's budget.
          Over time, our goal is to save more in energy costs than what we
          spend.
          <br></br>
          <br></br>We are accomplishing this by building a portfolio of projects
          that generate savings year after year. The buildings we optimized in
          2016 are still consuming less energy today than they were before our
          project, and are still generating savings today.
        </div>
        <div className="total-savings-title">Yearly Cumulative Savings</div>
        <div className="total-savings-overview">
          <div className="savings-overview-table">
            <div className="overview-table-header">
              <div className="overview-table-content" style={{ width: "64%" }}>
                Fiscal Year
              </div>
              <div
                className="overview-table-content right"
                style={{ width: "34%" }}
              >
                Savings
              </div>
            </div>
            {this.state.fiscalYears.map(year => {
              return (
                <div className="overview-data-row" key={year}>
                  <div
                    className="overview-table-content"
                    style={{ width: "64%" }}
                  >
                    {year}
                    {year === CURRENT_FY && " (Projected)"}
                  </div>
                  <div
                    className="overview-table-content right"
                    style={{ width: "34%" }}
                  >
                    {utilities.formatDollarValue(
                      year !== CURRENT_FY
                        ? parseInt(this.state.annualSavings[year])
                        : this.state.currentFyProjected
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="savings-overview-chart">
            <TotalSavingsChart
              years={this.state.fiscalYears}
              savings={this.state.annualSavings}
            />
          </div>
        </div>
        <CurrentSavings
          fySavings={this.state.currentFySavings}
          fyTotal={this.state.currentFyTotal}
          projectedSavings={this.state.currentFyProjected}
        />
      </div>
    );
  }
}

TotalSavings.propTypes = {
  projects: PropTypes.object.isRequired,
  totals: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    projects: state.buildingProjects,
    totals: state.buildingProjects.totals
  };
};

export default connect(mapStateToProps)(TotalSavings);
