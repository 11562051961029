import "focus-visible/dist/focus-visible.js";

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import * as mapDataActions from "./actions/mapDataActions";
import * as mapSearchActions from "./actions/mapSearchActions";

import Main from "./components/main/Main";
import Building from "./components/building/Building";
import Chcp from "./components/chcp/Chcp";
import Campus from "./components/campus/Campus";
import DataDownload from "./components/dataDownload/DataDownload";
import ErrorMessage from "./components/errorMessage/ErrorMessage";
import EnergySaving from "./components/energySaving/EnergySaving";
import Ewc from "./components/ewc/Ewc";
import Water from "./components/water/Water";
import Tlc from "./components/tlc/Tlc";

// Google Analytics integration
import Analytics from "react-router-ga";
const googleAnalyticsID = "UA-48993900-1";

const routes = [
  {
    path: "/",
    exact: true,
    component: Main
  },
  {
    path: "/building/:buildingId",
    exact: true,
    component: Building
  },
  // {
  //   path: "/chcp",
  //   exact: true,
  //   component: Chcp
  // },
  {
    path: "/energystory",
    exact: true,
    component: Campus
  },
  {
    path: "/datadownload",
    exact: true,
    component: DataDownload
  },
  { path: "/energysaving", exact: true, component: EnergySaving },
  {
    path: "/ewc",
    exact: true,
    component: Ewc
  },
  {
    path: "/water",
    exact: true,
    component: Water
  },
  {
    path: "/tlc",
    exact: true,
    component: Tlc
  },
  {
    component: Main
  }
];

class App extends React.Component {
  componentWillMount() {
    this.props.mapActions.checkConnections();
    this.props.searchActions.loadMapSearchData();
  }

  render() {
    // if (
    //   this.props.connectionData.Status === undefined ||
    //   this.props.connectionData.Status !== "UP"
    // )
    //   return <ErrorMessage />;
    return (
      <Router>
        <Analytics id={googleAnalyticsID}>
          <Switch>
            <Redirect from="/energystory/overview" to="/energystory" />
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
          </Switch>
        </Analytics>
      </Router>
    );
  }
}

App.propTypes = {
  mapActions: PropTypes.object.isRequired,
  searchActions: PropTypes.object.isRequired,
  connectionData: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    connectionData: state.connectionData
  };
}

function mapDispatchToProps(dispatch) {

  return {
    mapActions: bindActionCreators(mapDataActions, dispatch),
    searchActions: bindActionCreators(mapSearchActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
