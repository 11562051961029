import * as types from "./actionTypes";
import { createApolloFetch } from "apollo-fetch/dist/index";
import { costDataQuery } from "../queries/costDataQuery";

export const markerClickNeedCost = (clickStatus, buildingData) => {
  const uri = `/api/graphql`;
  const apolloFetch = createApolloFetch({ uri });
  return function(dispatch) {
    apolloFetch({
      query: costDataQuery,
      variables: {
        id: buildingData.id
      }
    })
      .then(res => {
        buildingData.annualCost = res.data.annualCost
          ? res.data.annualCost.Value
          : null;
        let markerClickObject = {
          clickStatus,
          buildingData
        };
        dispatch(loadMarkerClickDataSuccess(markerClickObject));
      })
      .catch(error => {
        throw error;
      });
  };
};

export const markerClickHaveCost = (clickStatus, buildingData) => {
  let markerClickObject = {
    clickStatus,
    buildingData
  };
  return function(dispatch) {
    dispatch(loadMarkerClickDataSuccess(markerClickObject));
  };
};

export const loadMarkerClickDataSuccess = markerClickObject => {
  return {
    type: types.LOAD_MARKER_DATA_SUCCESS,
    markerClickObject
  };
};

export const loadMapLocationSuccess = mapLocation => {
  return {
    type: types.LOAD_MAP_LOCATION_SUCCESS,
    mapLocation
  };
};

export const changeMapLocation = locationCenter => {
  let newLocation = {
    lat: locationCenter.lat,
    lng: locationCenter.long,
    zoom: 18
  };
  return function(dispatch) {
    dispatch(loadMapLocationSuccess(newLocation));
  };
};
