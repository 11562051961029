import React from "react";
import PropTypes from "prop-types";
import Calendar from "react-calendar";
import DropDown, { RADIO } from "../dropdown/Dropdown";
import SelectedData from "./selectedData/SelectedData";
import DataTypeSelection from "./typeSelection/DataTypeSelection";
import UnitTypeSelection from "./typeSelection/UnitTypeSelection";
import OutsideAirTempSelection from "./typeSelection/OatSelection";
import "./DataFilter.css";

const TIME_INTERVALS = {
  EVERY_15_MINUTES: "15m",
  EVERY_1_HOUR: "1h",
  EVERY_24_HOURS: "1d",
  WEEKLY_DATA_POINTS: "7d",
  MONTHLY_DATA_POINTS: "1mo",
  NONE: "none"
};

export const DATA_TYPE = {
  USAGE_DATA: "MonthlyUsage",
  DEMAND_DATA: "Demand",
  NONE: "none"
};

export const UNIT_TYPE = {
  KBTU: "kbtu",
  METER_UNITS: "meter",
  NONE: "none"
};

class DataFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataType: DATA_TYPE.NONE,
      unitType: UNIT_TYPE.NONE,
      outsideAirTemp: false,
      interval: TIME_INTERVALS.NONE,
      startDate: null,
      endDate: null,
      showStartCalendar: false,
      showEndCalendar: false,
      canStartDownload: false,
      name: "",
      company: "",
      email: ""
    };

    this.toggleDataTypeSelection = this.toggleDataTypeSelection.bind(this);
    this.toggleUnitTypeSelection = this.toggleUnitTypeSelection.bind(this);
    this.toggleStartDateCalendar = this.toggleStartDateCalendar.bind(this);
    this.toggleEndDateCalendar = this.toggleEndDateCalendar.bind(this);
    this.selectTimeIntervalHandler = this.selectTimeIntervalHandler.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.beginDownload = this.beginDownload.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      if (this.canBeginDownload() && !this.state.canStartDownload)
        this.setState({ canStartDownload: true });
      else if (!this.canBeginDownload() && this.state.canStartDownload)
        this.setState({ canStartDownload: false });
    }
    if (
      this.state.dataType !== prevState.dataType &&
      this.state.dataType === DATA_TYPE.USAGE_DATA
    )
      this.setState({
        unitType: UNIT_TYPE.METER_UNITS,
        interval: TIME_INTERVALS.MONTHLY_DATA_POINTS
      });
    if (
      prevState.dataType === DATA_TYPE.USAGE_DATA &&
      this.state.dataType === DATA_TYPE.DEMAND_DATA
    )
      this.setState({
        unitType: UNIT_TYPE.NONE,
        interval: TIME_INTERVALS.NONE
      });
    if (
      !this.props.beginDownload &&
      this.props.beginDownload !== prevProps.beginDownload
    ) {
      this.setState({
        dataType: DATA_TYPE.NONE,
        unitType: UNIT_TYPE.NONE,
        outsideAirTemp: false,
        interval: TIME_INTERVALS.NONE,
        startDate: null,
        endDate: null,
        showStartCalendar: false,
        showEndCalendar: false,
        canStartDownload: false,
        name: "",
        company: "",
        email: ""
      });
    }
  }

  beginDownload(event) {
    //Call the action and pass the data;
    if (this.state.canStartDownload) {
      this.props.startDownload(this.state);
    }
  }

  canBeginDownload() {
    let calendarReady =
        this.state.startDate !== null && this.state.startDate !== undefined,
      dataTypeReady = this.state.dataType !== DATA_TYPE.NONE,
      unitTypeReady = this.state.unitType !== UNIT_TYPE.NONE,
      hasBuildings = this.props.selectedBuildings.length !== 0,
      hasValidEmail = this.validateEmail();
    return (
      calendarReady &&
      dataTypeReady &&
      unitTypeReady &&
      hasBuildings &&
      hasValidEmail
    );
  }

  formatDisplayDate(date) {
    if (date === null) {
      return "-- | -- | --";
    }
    var day = date.getDate();
    var month = date.getMonth() + 1; //Month from 0 to 11
    var year =
      date.getFullYear() > 2000
        ? date.getFullYear() - 2000
        : date.getFullYear() - 1000;
    return (
      "" +
      (month <= 9 ? "0" + month : month) +
      " | " +
      (day <= 9 ? "0" + day : day) +
      " | " +
      year
    );
  }

  formatPiDate(date) {
    if (date === null) {
      return null;
    }
    var day = date.getDate();
    var month = date.getMonth() + 1; //Month from 0 to 11
    var year = date.getFullYear();
    return (
      "" +
      (month <= 9 ? "0" + month : month) +
      "-" +
      (day <= 9 ? "0" + day : day) +
      "-" +
      year
    );
  }

  getEndDate() {
    return this.state.endDate === null ? new Date() : this.state.endDate;
  }

  getTimeIntervalItems = () => {
    if (this.state.dataType === "MonthlyUsage")
      return [
        {
          text: "Monthly data points",
          value: TIME_INTERVALS.MONTHLY_DATA_POINTS,
          selected: this.state.interval === TIME_INTERVALS.MONTHLY_DATA_POINTS
        }
      ];
    return [
      {
        text: "Every 15 minutes",
        value: TIME_INTERVALS.EVERY_15_MINUTES,
        selected: this.state.interval === TIME_INTERVALS.EVERY_15_MINUTES
      },
      {
        text: "Every hour",
        value: TIME_INTERVALS.EVERY_1_HOUR,
        selected: this.state.interval === TIME_INTERVALS.EVERY_1_HOUR
      },
      {
        text: "Every 24 hours",
        value: TIME_INTERVALS.EVERY_24_HOURS,
        selected: this.state.interval === TIME_INTERVALS.EVERY_24_HOURS
      },
      {
        text: "Weekly data points",
        value: TIME_INTERVALS.WEEKLY_DATA_POINTS,
        selected: this.state.interval === TIME_INTERVALS.WEEKLY_DATA_POINTS
      },
      {
        text: "Monthly data points",
        value: TIME_INTERVALS.MONTHLY_DATA_POINTS,
        selected: this.state.interval === TIME_INTERVALS.MONTHLY_DATA_POINTS
      }
    ];
  };

  getStartDate() {
    return this.state.startDate === null ? new Date() : this.state.startDate;
  }

  selectTimeIntervalHandler(interval) {
    this.setState({ interval });
  }

  toggleDataTypeSelection = event => {
    this.setState({ dataType: event.target.value });
  };

  toggleUnitTypeSelection = event => {
    this.setState({ unitType: event.target.value });
  };

  toggleOatSelection = event => {
    this.setState({ outsideAirTemp: !this.state.outsideAirTemp });
  };

  setStartDate = startDate => {
    this.setState({
      startDate,
      showStartCalendar: !this.state.showStartCalendar
    });
  };

  setEndDate = endDate => {
    this.setState({
      endDate,
      showEndCalendar: !this.state.showEndCalendar
    });
  };

  toggleStartDateCalendar() {
    this.setState({ showStartCalendar: !this.state.showStartCalendar });
  }

  toggleEndDateCalendar() {
    this.setState({ showEndCalendar: !this.state.showEndCalendar });
  }

  updateText(field, value) {
    this.setState({ [field]: value });
  }

  validateEmail() {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))
      return true;
    return false;
  }

  render() {
    return (
      <div className="datadownload-outer-filter">
        <div className="datadownload-inner-filter graph-card">
          <SelectedData
            selectedBuildings={this.props.selectedBuildings}
            removeBuilding={this.props.removeBuilding}
          />
          <div className="data-filter-section data-filter-outer-row data-type-group">
            <DataTypeSelection
              selectedDataType={this.state.dataType}
              toggleDataTypeSelection={this.toggleDataTypeSelection}
            />
            <UnitTypeSelection
              selectedUnitType={this.state.unitType}
              toggleUnitTypeSelection={this.toggleUnitTypeSelection}
              usageSelected={
                this.state.dataType === DATA_TYPE.USAGE_DATA ? true : false
              }
            />
            <OutsideAirTempSelection
              isChecked={this.state.outsideAirTemp}
              toggleOatSelection={this.toggleOatSelection}
            />
          </div>
          <div className="data-filter-section data-filter-outer-row">
            <div className="data-filter-inner-column">
              <div className="data-filter-section-header">
                Select Interval and Date Range:
              </div>
              <div className="date-range-outer-group">
                <div className="date-range-group">
                  <div className="header-text" id="time-interval-container">
                    Time Interval*
                  </div>
                  <DropDown
                    id="time-interval-dropdown"
                    items={this.getTimeIntervalItems()}
                    type={RADIO}
                    onSelectHandler={this.selectTimeIntervalHandler}
                    defaultSelectText="Select time interval"
                    customClass={
                      this.state.interval === TIME_INTERVALS.NONE
                        ? "dropdown-time-interval"
                        : "dropdown-time-interval-highlight"
                    }
                    expandedClass="dropdown-time-interval-expanded"
                  />
                </div>
                <div className="date-range-group">
                  <div className="header-text" id="time-interval-container">
                    Start Date*
                  </div>
                  {this.state.showStartCalendar === false ? (
                    <div
                      className={
                        this.state.startDate === null
                          ? "input-box input-box-unselected"
                          : "input-box input-box-selected"
                      }
                      onClick={this.toggleStartDateCalendar}
                    >
                      {this.formatDisplayDate(this.state.startDate)}
                    </div>
                  ) : (
                    <Calendar
                      onChange={this.setStartDate}
                      value={this.getStartDate()}
                    />
                  )}
                </div>
                <div className="date-range-group">
                  <div className="header-text" id="time-interval-container">
                    End Date*
                  </div>
                  {this.state.showEndCalendar === false ? (
                    <div
                      className={
                        this.state.endDate === null
                          ? "input-box input-box-unselected"
                          : "input-box input-box-selected"
                      }
                      onClick={this.toggleEndDateCalendar}
                    >
                      {this.formatDisplayDate(this.state.endDate)}
                    </div>
                  ) : (
                    <Calendar
                      onChange={this.setEndDate}
                      value={this.getEndDate()}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="data-filter-section data-filter-outer-row">
            <div className="data-filter-entry-text">
              <div className="data-filter-name">
                <div className="header-text" id="name">
                  Your Name
                </div>
                <input
                  type="text"
                  value={this.state.name}
                  placeholder="Your Name"
                  onChange={e => {
                    this.updateText("name", e.target.value);
                  }}
                />
              </div>
              <div className="data-filter-company">
                <div className="header-text" id="company">
                  Your Company
                </div>
                <input
                  type="text"
                  value={this.state.company}
                  placeholder="Your Company"
                  onChange={e => {
                    this.updateText("company", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="data-filter-section data-filter-outer-row">
            <div className="data-filter-inner-column">
              <div className="emailandsend-group">
                <div className="header-text" id="email-address">
                  Email Address*
                </div>
                <input
                  type="text"
                  value={this.state.email}
                  placeholder="Your Email"
                  onChange={e => {
                    this.updateText("email", e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              className={
                "download-button" +
                (this.state.canStartDownload ? " active" : "")
              }
              onClick={e => this.beginDownload()}
            >
              <span>Begin Download</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DataFilter.propTypes = {
  selectedBuildings: PropTypes.object.isRequired,
  removeBuilding: PropTypes.func.isRequired,
  startDownload: PropTypes.func.isRequired
};

export default DataFilter;
