/**
 * Actions used for the energy saving projects page building projects tab.
 */

import * as types from "../actionTypes";
import { createApolloFetch } from "apollo-fetch";
import * as graphqlQueries from "../../queries/energySavingQuery";

const uri = `/api/graphql`;
const apolloFetch = createApolloFetch({ uri });

const FY_START_MONTH = 3; //FY Start is April for Energy and Engineering

// Pushes the data to the store.
export const loadBuildingProjectsSuccess = buildingProjects => {
  return {
    type: types.LOAD_BUILDING_PROJECTS_SUCCESS,
    buildingProjects
  };
};

// Initial call for loading the full campus data.
export const loadBuildingsProjectsData = () => {
  return dispatch => {
    Promise.all([
      getOpportunitiesLog(),
      getHvacScheduling(),
      getHolidayShutdowns(),
      getAceFYsavings()
    ]).then(async results => {
      let projectsData = {
        opportunitiesLog: await setOpFY(results[0]),
        hvacScheduling: results[1],
        holidayShutdowns: await setFY(results[2]),
        aceFYsavings: results[3],
        totals: {
          opportunitiesLog: await setTotal(results[0], "savings"),
          hvacScheduling: await setTotal(results[1], "savings"),
          holidayShutdowns: await setTotal(results[2], "totalSavings")
        },
        currentFYtotals: {
          currentFY: await currFY(),
          holidayFY: await FYforHoliday(),
          opportunitiesLog: await setFYTotal(results[0], "savings"),
          holidayShutdowns: await setFYTotal(results[2], "totalSavings"),
          mnvTotals: getmnvTotals
        }
      };
      dispatch(loadBuildingProjectsSuccess(projectsData));
    });
  };
};

const getHolidayShutdowns = () => {
  return new Promise((res, rej) => {
    try {
      apolloFetch({
        query: graphqlQueries.holidayShutdownsQuery
      }).then(holidaysData => {
        if (
          holidaysData.data !== undefined &&
          holidaysData.data.holidayShutdowns !== undefined
        ) {
          for (let i = 0; i < holidaysData.data.holidayShutdowns.length; i++)
            if (holidaysData.data.holidayShutdowns[i] !== undefined)
              holidaysData.data.holidayShutdowns[i].totalSavings = parseFloat(
                holidaysData.data.holidayShutdowns[i].totalSavings
              );
          res(
            holidaysData.data.holidayShutdowns.sort((a, b) =>
              a.totalSavings < b.totalSavings ? 1 : -1
            )
          );
        } else rej("Unable to retrieve holidays log data.");
      });
    } catch (e) {
      rej("Unable to fetch data for holidays log data.");
    }
  });
};

const getAceFYsavings = () => {
  return new Promise((res, rej) => {
    apolloFetch({
      query: graphqlQueries.aceFYSavingsQuery
    }).then(aceFYsavingsData => {
      res(aceFYsavingsData.data);
    });
  });
};

// TODO: REPLACE WITH CEED ADMIN QUERY
const getmnvTotals = [
  {
    commodity: "Holiday Total", // Holiday Shutdown
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value:  48608
      }
    }
  },
  {
    commodity: "Opportunity Total", // Opportunities Log
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value:  604393
      }
    }
  },
  {
    commodity: "Hvac Total", // HVAC Scheduling
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 158229
      }
    }
  },
  {
    commodity: "Building Commission Total", // Building Projects
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 1145842
      }
    }
  },
  {
    commodity: "Other Projects", // Other Projects
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 302794
      }
    }
  },
  {
    commodity: "Total Savings", // Total
    data: {
      pastFY: {
        fy: 2023,
        value: 0 // TODO: add value
      },
      currentFY: {
        fy: 2024,
        value: 2259866
      }
    }
  },
  // {
  //   commodity: "Forecasted Savings", // TODO: find tag
  //   data: {
  //     pastFY: {
  //       fy: 2023,
  //       value: 0 // TODO: add value
  //     },
  //     currentFY: {
  //       fy: 2024,
  //       value: 2259866
  //     }
  //   }
  // },
];
//           commodity,
//           data: {
//             pastFY: {
//               fy: pastFiscalYear,
//               value: Math.round(pastFiscalYearData)
//             },
//             currentFY: {
//               fy: currentFiscalYear,
//               value: Math.round(currentFiscalYearData)
//             }
//           }

const getHvacScheduling = () => {
  return new Promise((res, rej) => {
    try {
      apolloFetch({
        query: graphqlQueries.hvacSchedulingQuery
      }).then(hvacData => {
        if (
          hvacData.data !== undefined &&
          hvacData.data.hvacScheduling !== undefined
        )
          res(hvacData.data.hvacScheduling);
        else rej("Unable to retrieve HVAC log data.");
      });
    } catch (e) {
      rej("Unable to fetch data for HVAC log data.");
    }
  });
};

const getOpportunitiesLog = () => {
  return new Promise((res, rej) => {
    try {
      apolloFetch({
        query: graphqlQueries.opportunitiesLogQuery
      }).then(opportunitiesData => {
        if (
          opportunitiesData.data !== undefined &&
          opportunitiesData.data.opportunitiesLog !== undefined
        )
          res(opportunitiesData.data.opportunitiesLog);
        else rej("Unable to retrieve opportunities log data.");
      });
    } catch (e) {
      rej("Unable to fetch data for opportunities log data.");
    }
  });
};

const getTwoDigitYear = year => {
  return year.toString().slice(2);
};

const setFY = dataArray => {
  return new Promise((res, rej) => {
    try {
      for (let i = 0; i < dataArray.length; i++) {
        let tempDate = new Date(dataArray[i].startDate);
        if (tempDate.getMonth() >= FY_START_MONTH)
          dataArray[i].fy =
            tempDate.getFullYear() +
            "-" +
            getTwoDigitYear(parseInt(tempDate.getFullYear() + 1));
        else
          dataArray[i].fy =
            parseInt(tempDate.getFullYear() - 1) +
            "-" +
            getTwoDigitYear(tempDate.getFullYear());
      }
      res(dataArray);
    } catch (e) {
      throw new Error(e);
      res(dataArray);
    }
  });
};

const setOpFY = dataArray => {
  return new Promise((res, rej) => {
    try {
      for (let i = 0; i < dataArray.length; i++) {
        let tempDate = new Date(dataArray[i].date);
        if (tempDate.getMonth() >= FY_START_MONTH)
          dataArray[i].fy =
            tempDate.getFullYear() +
            "-" +
            getTwoDigitYear(parseInt(tempDate.getFullYear() + 1));
        else
          dataArray[i].fy =
            parseInt(tempDate.getFullYear() - 1) +
            "-" +
            getTwoDigitYear(tempDate.getFullYear());
      }
      res(dataArray);
    } catch (e) {
      throw new Error(e);
      res(dataArray);
    }
  });
};

const setTotal = (dataSet, property) => {
  return new Promise((resolve, reject) => {
    try {
      let total = 0;
      for (let i = 0; i < dataSet.length; i++)
        if (typeof dataSet[i][property] === "number")
          total += dataSet[i][property];
        else total += parseFloat(dataSet[i][property]);
      resolve(Math.round(total));
    } catch (e) {
      resolve(0);
    }
  });
};

const setFYTotal = (dataSet, property) => {
  let CurrentYear = new Date().getFullYear();
  let currentFYear =
    parseInt(CurrentYear - 1) + "-" + getTwoDigitYear(CurrentYear);
  return new Promise((resolve, reject) => {
    try {
      let total = 0;
      for (let i = 0; i < dataSet.length; i++)
        if (
          typeof dataSet[i][property] === "number" &&
          dataSet[i]["fy"] === currentFYear
        )
          total += dataSet[i][property];
      resolve(Math.round(total));
    } catch (e) {
      resolve(0);
    }
  });
};
const currFY = () => {
  let CurrentYear = new Date().getFullYear();
  let Month = new Date().getMonth();
  if (Month < FY_START_MONTH) {
    let currentFYear =
      getTwoDigitYear(CurrentYear - 2) + "-" + getTwoDigitYear(CurrentYear - 1);
    return currentFYear;
  } else {
    let currentFYear =
      getTwoDigitYear(CurrentYear - 1) + "-" + getTwoDigitYear(CurrentYear);
    return currentFYear;
  }
};

const FYforHoliday = () => {
  let CurrentYear = new Date().getFullYear();
  let Month = new Date().getMonth();
  if (Month < FY_START_MONTH) {
    let currentFYear = CurrentYear - 1 + "-" + getTwoDigitYear(CurrentYear);
    return currentFYear;
  } else {
    let currentFYear =
      getTwoDigitYear(CurrentYear) + "-" + getTwoDigitYear(CurrentYear + 1);
    return currentFYear;
  }
};
