import * as types from "./actionTypes";
import { createApolloFetch } from "apollo-fetch";
import axios from "axios";
import Dexie from "dexie";
import {
  mobileBuildingsQuery,
  mobileAnnualUsageQuery,
  mobileDemandQuery
} from "../queries/mobileQuery";


const ACE_BUILDINGS = ["ghausi", "pes", "vm3a", "shields"],
  AVERAGE_CA_HOME_USE = 554 * 12,
  KWH_CONVERSION = 3.1421416,
  MOBILE_COMMODITIES = ["electricity"],
  BUILDING_FORMAT = {
    id: "",
    name: "",
    buildingType: "",
    kwh: 0,
    homes: 0
  },
  API_URI = `/api/graphql`,
  RANDOM_KEY_LENGTH = 40;
const db = new Dexie("cached");
db.version(1).stores({
  objects: "id"
});

const loadCeedMobileSuccess = ceedMobileData => {
  return {
    type: types.LOAD_CEED_MOBILE_SUCCESS,
    ceedMobileData
  };
};

const addZeroString = digit => {
  if (digit < 10) return "0" + digit;
  return digit;
};

const checkQueryLabel = id => {
  return id.replace(/\./g, "_");
};

const convertToHomes = amount => {
  return Math.round(amount / AVERAGE_CA_HOME_USE);
};

const getAnnualUsage = (link, variables) => {
  return new Promise((resolve, reject) => {
    const apollo = createApolloFetch({ uri: link });
    let queryInfo = mobileAnnualUsageQuery(
      checkQueryLabel(variables.buildingId)
    );
    apollo({ query: queryInfo, variables }).then(usageData => {
      resolve(usageData);
    });
  });
};

const getAnnualKwhTotal = values => {
  return new Promise((resolve, reject) => {
    let total = 0;
    for (let z = 0; z < values.length; z++) total += values[z].Value;
    resolve(Math.round(total / KWH_CONVERSION));
  });
};

// Returns 12 hours prior to current time.
const getHalfDayTimestamp = currentTime => {
  return new Date(currentTime.getTime() - 12 * 60 * 60 * 1000);
};

// Returns 3 days prior to current time.
const getThreeDayTimestamp = currentTime => {
  return new Date(currentTime.getTime() - 3 * 24 * 60 * 60 * 1000);
};

// Returns 7 days prior to current time.
const getSevenDayTimestamp = currentTime => {
  return new Date(currentTime.getTime() - 7 * 24 * 60 * 60 * 1000);
};

const setAnnualUsageParameters = building => {
  let today = new Date(),
    lastMonth = today.getMonth(),
    thisYear = lastMonth === 0 ? today.getFullYear() - 1 : today.getFullYear(),
    lastYear = lastMonth === 0 ? thisYear : thisYear - 1,
    thisMonth = lastMonth + 1;
  if (lastMonth === 0) lastMonth = 12;
  return {
    buildingId: building.id,
    commodities: MOBILE_COMMODITIES,
    startMonth: lastYear + "-" + addZeroString(thisMonth),
    endMonth: thisYear + "-" + addZeroString(lastMonth)
  };
};

const setBuildingObject = async (usage, building) => {
  let tempFormat = Object.assign({}, BUILDING_FORMAT);
  tempFormat.id = building.id;
  tempFormat.name = building.name;
  tempFormat.buildingType = building.buildingType;
  tempFormat.kwh = await getAnnualKwhTotal(usage.data);
  tempFormat.homes = convertToHomes(tempFormat.kwh);
  return tempFormat;
};

const setFinalDataArrays = (usageData, buildingData) => {
  return new Promise(async (resolve, reject) => {
    let tempAll = [],
      tempAce = [],
      tempClassroom = [],
      tempGreen = [],
      tempLab = [];
    for (let i = 0; i < buildingData.length; i++)
      for (let j = 0; j < usageData.length; j++)
        if (
          usageData[j].data !== undefined &&
          usageData[j].errors === undefined
        )
          for (let key in usageData[j].data)
            if (key === checkQueryLabel(buildingData[i].id)) {
              let tempBuildingObject = await setBuildingObject(
                usageData[j].data[key][0],
                buildingData[i]
              );
              tempAll.push(tempBuildingObject);
              for (let k = 0; k < ACE_BUILDINGS.length; k++)
                if (ACE_BUILDINGS[k] === buildingData[i].id)
                  tempAce.push(tempBuildingObject);
              if (buildingData[i].buildingType === "classroom")
                tempClassroom.push(tempBuildingObject);
              if (
                buildingData[i].certifications !== null &&
                buildingData[i].certifications.length > 0
              )
                tempGreen.push(tempBuildingObject);
              if (buildingData[i].buildingType === "lab")
                tempLab.push(tempBuildingObject);
            }
    resolve({
      all: await sortBuildings(tempAll),
      ace: await sortBuildings(tempAce),
      classroom: await sortBuildings(tempClassroom),
      green: await sortBuildings(tempGreen),
      lab: await sortBuildings(tempLab)
    });
  });
};

export const loadCeedMobileData = () => {
  const apolloFetch = createApolloFetch({ uri: API_URI });
  return function(dispatch) {
    apolloFetch({ query: mobileBuildingsQuery })
      .then(async buildingData => {
        const promises = buildingData.data.buildings.map(async building => {
          return await getAnnualUsage(
            API_URI,
            setAnnualUsageParameters(building)
          );
        });
        Promise.all(promises).then(async results => {
          let finalData = await setFinalDataArrays(
            results,
            buildingData.data.buildings
          );
          dispatch(loadCeedMobileSuccess(finalData));
        });
      })
      .catch(error => {
        throw error;
      });
  };
};

export const loadMobileBuildingData = buildingId => {
  const apolloFetch = createApolloFetch({ uri: API_URI });
  let currentTime = new Date(),
    variables = {
      id: buildingId,
      commodities: MOBILE_COMMODITIES,
      currentTime,
      halfDayStart: getHalfDayTimestamp(currentTime),
      threeDayStart: getThreeDayTimestamp(currentTime),
      sevenDayStart: getSevenDayTimestamp(currentTime)
    };
  return dispatch => {
    apolloFetch({ query: mobileDemandQuery, variables }).then(
      async demandData => {
        let finalData = {
          halfDay: demandData.data.halfDay[0].data,
          threeDay: demandData.data.threeDay[0].data,
          sevenDay: demandData.data.sevenDay[0].data
        };
        dispatch(loadCeedMobileSuccess({ buildingDemand: finalData }));
      }
    );
  };
};

const randomChar = () => {
  let chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!-_";
  return chars.charAt(Math.floor(Math.random() * chars.length));
};

const sortBuildings = buildingArray => {
  return buildingArray.sort((a, b) => {
    return a.name > b.name ? 1 : -1;
  });
};

export const sendSurvey = surveyData => {
  return dispatch => {
    let range = RANDOM_KEY_LENGTH - surveyData.email.length,
      randomValue = Math.floor(Math.random() * range),
      keyValue = "";
    for (let i = 0; i < RANDOM_KEY_LENGTH; i++) {
      if (i === randomValue) {
        keyValue += surveyData.email;
        i += surveyData.email.length;
      }
      if (i < RANDOM_KEY_LENGTH) keyValue += randomChar();
    }
    const surveyUpdate = {
      method: "POST",
      uri:
        window.location.protocol +
        "//" +
        window.location.host +
        "/api/airtable/" +
        keyValue,
      body: surveyData,
      json: true
    };
    axios(surveyUpdate)
      .then(results => {
        if (results.Posted === "Success")
          dispatch(loadCeedMobileSuccess({ posted: true }));
      })
      .catch(error => {
        throw error;
      });
  };
};
